@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
// Vex Core
@import "./@vex/styles/core";

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: #333333;
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/

/**
  Uncomment the below code and adjust the values to fit your colors, the application will automatically adjust
 */
:root {
  
  [mat-dialog-title] {
    margin: -24px -24px 24px -24px !important;
    padding: 5px 24px;
    border-radius: 8px;
    
      background: linear-gradient(to bottom, #9c27b01a, white);
   /*background: #369;
    color: #fff; */
    cursor: move;
  }

  [mat-tab-label] {
    min-width : 0px; // added
    width: auto;
    //height: 2%;
    border-top-left-radius: 23px !important;
    border-top-right-radius: 23px !important;
    //background-color: #4345a4;
    background-color: #e0e0e0;
    //margin-bottom: 2%;
    margin: 0.1% !important;
   }
  }


  .center-div {
    text-align:center;
    width: 50%;
    margin: 0 auto
  }
  
  .mat-form-field-flex {
    background-color: #ffffff !important;
  }
  
  .mat-card-header-text {
    margin: 0 0px !important;
  }
  
  .mat-card-header {
    font-size: 16pt;
    margin-bottom: 30px;
  }
  
  .card-content {
    font-size: 12pt;
  }
  
  ::ng-deep .mat-dialog-container{
    display: block;
    padding: 24px 0px 24px 0px;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: auto;
    outline: 0;
    width: 100%;
    height: 100%;
    min-height: inherit;
    max-height: inherit;
  }

  
  
 // --sidenav-background: #9FEDE5;
// --sidenav-background: #9FEDE5;
  

